class DataManager {
    constructor() {
        this.limit = 0;
        this.size = 0;
        this.offset = 0;
        this.records = 0;

        this.page = 0;
        this.count = 0;
        this.end = false;
        this.list = [];
        this.clist = [];
    }

    buffer(buffer) {
        this.limit = buffer.limit;
        this.size = buffer.size;
        this.offset = buffer.offset;
        this.records = buffer.records;

        this.page = this.offset + buffer.show;
        this.count = Math.ceil(this.records / this.size);
        this.end = (this.offset + this.limit) * this.size >= this.records;
        this.list = buffer.list;
    }

    isChanged(id) {
        return this.clist.indexOf(id) >= 0;
    }

    setPage(i) {
        this.page = i;
    }

    parameter(i, refresh = false) {
        if (this.count === 0) return refresh ? '' : 'IDList=';

        if (i < 0) i = 0;
        if (i > this.count - 1) i = this.count - 1;
        if (!refresh && this.offset <= i && (this.end || i < this.offset + Math.floor(this.list.length / this.size))) {
            const base = (i - this.offset) * this.size;
            return 'IDList=' + this.list.slice(base, base + this.size).join(',');
        }
        const back = Math.floor(this.limit / 2 - 0.5);
        return i > back ? ('offset=' + (i - back) + '&show=' + back) : ('offset=0&show=' + i);
    }

    isFirst(id) {
        return this.offset === 0 && this.list[0] === id;
    }

    isLast(id) {
        return this.offset * this.size + this.list.length === this.records && this.list[this.list.length - 1] === id;
    }

    add(id) {
        this.records += 1;
        this.count = Math.ceil(this.records / this.size);
        this.list.splice((this.page + 1) * this.size - 1, 0, id);
        this.clist.push(id);
    }

    merge(list) {
        this.records += list.length;
        this.count = Math.ceil(this.records / this.size);
        let position = (this.page + 1) * this.size - list.length;
        if (position < 0) position = 0;
        this.list = this.list.slice(0, position).concat(list).concat(this.list.slice(position));
        this.clist = this.clist.concat(list);
    }

    push(id) {
        this.records += 1;
        this.count = Math.ceil(this.records / this.size);
        this.list.push(id);
    }

    swap(id1, id2) {
        const pos1 = this.list.indexOf(id1);
        const pos2 = this.list.indexOf(id2);
        if (pos1 < 0 || pos2 < 0) return;
        this.list[pos1] = id2;
        this.list[pos2] = id1;
    }

    remove(list) {
        this.records -= list.length;
        this.count = Math.ceil(this.records / this.size);
        if (this.page >= this.count) this.page = this.count - 1;
        if (this.page < 0) this.page = 0;
        this.list = this.list.filter(function (e) {
            return !list.includes(e);
        });
    }

    update(list) {
        this.clist = this.clist.concat(list);
    }
}



export default DataManager;