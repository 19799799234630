import * as React from 'react';



class StatusBar extends React.Component {
    render() {
        return (
            <div className="status-bar">{window.lan.meta.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{window.lan.meta.copyright}</div>
        );
    }
}



export default StatusBar;