import { initialize_title, modify_title } from '../../main/App';
import { getTimeStr, getIPStr } from '../../main/Utility';
import AbstractTable from '../../main/AbstractTable';



class Log extends AbstractTable {

    constructor(props) {
        super('/feature/log.basic',
            [
                { sortindex: 0, label: window.lan.log.id },
                { sortindex: 1, label: window.lan.log.name },
                { sortindex: -1, label: window.lan.log.inf, style: { width: '45%' } },
                { sortindex: -1, label: window.lan.log.operator_info },
                { sortindex: 5, label: window.lan.log.time },
                { sortindex: 4, label: window.lan.log.ip }
            ],
            window.lan.log.infobox, 'Log', props.path === '' ? '' : props.path.substr(1), '0$1',
            [
                { type: 0, name: window.lan.log.id, err: window.lan.log.err[0], fastsearch: true },
                { type: 3, name: window.lan.log.optype, fun: Log.getOperator, limit: 16 },
                { type: 0, name: window.lan.log.operator, err: window.lan.log.err[1], fastsearch: true },
                { type: 4, name: window.lan.log.operator_info, err: window.lan.log.err[2] },
                { type: 5, name: window.lan.log.time, err: window.lan.log.err[3] }
            ],
            [
                window.lan.log.id,
                window.lan.log.name,
                window.lan.log.optype,
                window.lan.log.operator,
                window.lan.log.ip,
                window.lan.log.time
            ], false);
    }

    static getOperator(type) {
        return window.lan.operatortype[type] ? window.lan.operatortype[type] : '';
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.log.title);
        return this.pdraw([{ info: window.lan.log.title }], null, this.tdraw());
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return window.lan.logname[row.type] ? window.lan.logname[row.type] : window.lan.history.unknown;
        if (cellindex === 3) return window.logfun[row.type] && row.information ? window.logfun[row.type](JSON.parse(row.information)) : '';
        if (cellindex === 4) return row.operatorType === 1 && row.operatorID === 1 ? window.lan.log.sys_admin : (window.lan.operatortype[row.operatorType] + (row.operatorInfo !== '' ? ' / ' : '') + row.operatorInfo);
        if (cellindex === 5) return getTimeStr(row.insertTime);
        if (cellindex === 6) return getIPStr(row.insertIP);
    }

    drawEmptyCell() {
        return window.lan.log.empty;
    }
}



export default Log;