import * as React from 'react';
import { TextInput, SelectInput } from './AbstractForm';
import { getTimeStr } from './Utility';



class Search extends React.Component {
    constructor(description, reference) {
        super();
        this.description = description;
        this.reference = reference;
        this.state = { id: 0 };
        this.initialize();
    }

    initialize() {
        Object.assign(this.reference.value, { operator: 0, valuelimit: '', option: -1, keyword: '', mode: 0, date: '', order: 0 });
    }

    getValidity() {
        const description = this.description[this.state.id];
        if (description.type >= 0 && description.type <= 2) return 'valuelimit';
        return null;
    }

    text(item) {
        const description = this.description[parseInt(item[0])];
        if (description.type >= 0 && description.type <= 2) return description.name + ': ' + (parseInt(item[2]) === 0 ? window.lan.filter.operatoreq : (parseInt(item[2]) === 1 ? window.lan.filter.operatorlt : window.lan.filter.operatorgt)) + ' ' + item[1];
        if (description.type === 3) return description.name + ': ' + description.fun(parseInt(item[1]));
        if (description.type === 4) return description.name + ': ' + decodeURIComponent(item[1]) + ' (' + (parseInt(item[2]) === 0 ? window.lan.filter.modefull : window.lan.filter.modematch) + ')';
        if (description.type === 5) {
            const date = new Date();
            let order = '';
            if (parseInt(item[2]) === 0) order = window.lan.filter.orderep;
            if (parseInt(item[2]) === 1) order = window.lan.filter.orderef;
            if (parseInt(item[2]) === 2) order = window.lan.filter.orderlp;
            if (parseInt(item[2]) === 3) order = window.lan.filter.orderlf;
            return description.name + ': ' + order + ' ' + (window.lan.filter.up_date === 0 ? (window.lan.filter.ut_date + ' ' + item[1]) : (item[1] + ' ' + window.lan.filter.ut_date)) +
                ' (' + (parseInt(item[2]) === 0 ? window.lan.filter.ordere : window.lan.filter.orderl) + ' ' +
                getTimeStr(Math.floor(date.getTime() / 1000) + (parseInt(item[2]) % 2 === 0 ? -1 : 1) * parseInt(item[1]) * 86400) + ')';
        }
    }

    render() {
        const description = this.description[this.state.id];
        if (description.type >= 0 && description.type <= 2) return (
            <React.Fragment>
                <SelectInput form={this.reference} id="operator" label={window.lan.filter.operator} options={[
                    { name: window.lan.filter.operatoreq, value: 0 },
                    { name: window.lan.filter.operatorlt, value: 1 },
                    { name: window.lan.filter.operatorgt, value: 2 }
                ]} />&nbsp;&nbsp;<TextInput form={this.reference} id="valuelimit" label={window.lan.filter.valuelimit} />
            </React.Fragment>
        );
        if (description.type === 3) {
            const options = [];
            for (let i = 0; i < description.limit; i++) {
                const res = description.fun(i);
                if (res === '') continue;
                if (this.reference.value.option < 0) this.reference.value.option = i;
                options.push({ name: res, value: i });
            }
            return (<SelectInput form={this.reference} id="option" label={window.lan.filter.option} options={options} />);
        }
        if (description.type === 4) return (
            <React.Fragment>
                <TextInput form={this.reference} id="keyword" label={window.lan.filter.keyword} />&nbsp;&nbsp;<SelectInput form={this.reference} id="mode" label={window.lan.filter.mode} options={[
                    { name: window.lan.filter.modefull, value: 0 },
                    { name: window.lan.filter.modematch, value: 1 }
                ]} />
            </React.Fragment>
        );
        if (description.type === 5) return (
            <React.Fragment>
                <SelectInput form={this.reference} id="order" label={window.lan.filter.order} options={[
                    { name: window.lan.filter.orderep, value: 0 },
                    { name: window.lan.filter.orderef, value: 1 },
                    { name: window.lan.filter.orderlp, value: 2 },
                    { name: window.lan.filter.orderlf, value: 3 }
                ]} />&nbsp;&nbsp;{<TextInput form={this.reference} id="date" label={window.lan.filter.date}
                    start={window.lan.filter.up_date === 0 ? window.lan.filter.ut_date : null} end={window.lan.filter.up_date === 1 ? window.lan.filter.ut_date : null} />}
            </React.Fragment>
        );
    }

    validate() {
        const description = this.description[this.state.id];
        if (description.type === 0) {
            const value = parseInt(this.reference.value.valuelimit);
            this.reference.validity.valuelimit = isNaN(value) || value < 1 || value > Number.MAX_SAFE_INTEGER ? description.err : '';
            return this.reference.validity.valuelimit === '';
        }
        if (description.type === 1) {
            const value = parseInt(this.reference.value.valuelimit);
            this.reference.validity.valuelimit = isNaN(value) || value < 0 || value > Number.MAX_SAFE_INTEGER ? description.err : '';
            return this.reference.validity.valuelimit === '';
        }
        if (description.type === 2) {
            const value = parseInt(this.reference.value.valuelimit);
            this.reference.validity.valuelimit = isNaN(value) || value < -Number.MAX_SAFE_INTEGER || value > Number.MAX_SAFE_INTEGER ? description.err : '';
            return this.reference.validity.valuelimit === '';
        }
        if (description.type === 4) {
            const value = this.reference.value.keyword;
            this.reference.validity.keyword = value.length < 1 || value.length > 255 ? description.err : '';
            return this.reference.validity.keyword === '';
        }
        if (description.type === 5) {
            const value = parseInt(this.reference.value.date);
            this.reference.validity.date = isNaN(value) || value < 0 || value > 32767 ? description.err : '';
            return this.reference.validity.date === '';
        }
        return true;
    }

    read() {
        const description = this.description[this.state.id];
        if (description.type >= 0 && description.type <= 2) {
            return [this.state.id.toString(), this.reference.value.valuelimit, this.reference.value.operator.toString()];
        }
        if (description.type === 3) {
            return [this.state.id.toString(), this.reference.value.option];
        }
        if (description.type === 4) {
            return [this.state.id.toString(), encodeURIComponent(this.reference.value.keyword), this.reference.value.mode.toString()];
        }
        if (description.type === 5) {
            return [this.state.id.toString(), this.reference.value.date.toString(), this.reference.value.order.toString()];
        }
    }
}



export default Search;