import logo from '../../logo.png';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';

import { load, load_component, refresh } from '../../main/App';



class MainBar extends React.Component {

    constructor() {
        super();
        this.state = ({ umopen: false, ummain: null });
    }

    render() {
        const parts = document.title.split(' - ');
        const password = load_component('password', '', this);
        const logout = load_component('logout', '', this);
        const menu = load_component('_menu', '', this);
        return (
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" elevation={0}>
                    <Toolbar disableGutters={true} variant="dense" className="main-bar">
                        <a href="/"><img src={logo} alt="logo" height="38px" style={{ padding: "8px", paddingBottom: "4px" }} /></a>
                        <span id="main_title">{parts[0]}</span>{password}{logout}
                        <Box sx={{ flexGrow: 1 }}>
                            {window.config.admin.id > 0 ? menu : null}
                        </Box>
                        <Button onClick={() => { refresh(); }}>{window.lan.main.refresh}</Button> <Button onClick={() => { window.open(window.location.href, '_blank'); void (0); }}>{window.lan.main.window}</Button>&nbsp;&nbsp;&nbsp;
                        {window.config.admin.id > 0 ? (
                            <>
                                <ButtonGroup size="large" color="light" variant="contained" disableElevation>
                                    <Button sx={{ textTransform: 'none' }} variant="outlined">{window.config.site.name}</Button>
                                    <Button sx={{ textTransform: 'none' }} color="secondary" endIcon={<ArrowDropDownIcon />}
                                        onClick={(e) => { this.setState({ umopen: !this.state.umopen, ummain: e.target }); }}>{window.config.admin.id > 1 ? window.config.admin.name : window.lan.main.process}</Button>
                                </ButtonGroup>
                                <Menu open={this.state.umopen} anchorEl={this.state.ummain} elevation={0} onClose={() => { this.setState({ umopen: false }); }}>
                                    <MenuItem dense={true} onClick={() => { this.setState({ umopen: false }); load('/history'); }} disableRipple><ListItemIcon><ManageSearchIcon fontSize="small" /></ListItemIcon><ListItemText>{window.lan.main.history}</ListItemText></MenuItem>
                                    <Divider />
                                    {window.config.admin.id > 1 ? (<MenuItem dense={true} onClick={() => { this.setState({ umopen: false }); this.password.setState({ mode: 1 }); }} disableRipple><ListItemIcon><PasswordIcon fontSize="small" /></ListItemIcon><ListItemText>{window.lan.main.password}</ListItemText></MenuItem>) : (null)}
                                    <MenuItem dense={true} onClick={() => { this.setState({ umopen: false }); this.logout.submit(); }} disableRipple><ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon><ListItemText>{window.lan.main.logout}</ListItemText></MenuItem>
                                </Menu>
                            </>
                        ) : (null)}
                        &nbsp;&nbsp;&nbsp;
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
}



export default MainBar;