import './App.css';

import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import config from '../config.json';
import { loadFeatures, loadPlugins } from '../loader';



const components = loadFeatures();
const plugins = loadPlugins();



window.onpopstate = function (event) {
    refresh();
}



export function loading(isloading) {
    window.appframe.setState({ isloading: isloading });
}



export function load(url) {
    window.history.pushState(null, null, url);
    refresh();
}



export function refresh() {
    window.appframe.setState({ id: window.appframe.state.id + 1 });
}



export function initialize_title() {
    document.title = window.config.site.name + ' - ' + window.lan.meta.title;
}



export function modify_title(level, value) {
    const parts = document.title.split(' - ').reverse();
    parts[level] = value;
    document.title = parts.reverse().join(' - ');
    if (document.getElementById('main_title')) document.getElementById('main_title').innerText = parts[0];
}



export function get_url(path) {
    return config.server + path;
}



export async function get_json(path) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function post_file(path, file, data) {
    try {
        const form = new FormData();
        if (file.name) form.append("file", file);
        form.append("json", JSON.stringify(data));
        const response = await fetch(config.server + path, { credentials: 'include', method: 'POST', body: form, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function post_json(path, data) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function put_json(path, data) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'PUT', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' }, timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export async function delete_json(path) {
    try {
        const response = await fetch(config.server + path, { credentials: 'include', method: 'DELETE', timeout: 2000 });
        if (!response.ok) return {};
        return await response.json();
    }
    catch (error) {
        return {};
    }
}



export function load_component(base, path, reference) {
    const variant = window.config.site.feature[base];
    if (!variant) return (null);
    const Component = components[base][variant];
    return (<Component key={'component_' + base + '_' + window.appframe.state.id} path={path} reference={reference} />);
}



export function load_plugin(base, variant, information, reference) {
    if (!window.config.plugin[base]) return (null);
    return new plugins[base][variant](variant, information, reference);
}



export function split_path(path) {
    const path_info = path.split('/');
    return [path_info[1], path.substring(path_info[1].length + 1)];
}



class Frame extends React.Component {
    render() {
        initialize_title();
        let search = window.location.pathname;
        if (search === '' || search === '/') search = '/' + window.config.defaultFeature;
        const [base, path] = split_path(search);
        const component = load_component(base, path);
        const mainbar = load_component('_mainbar');
        const statusbar = load_component('_statusbar');
        return <>{mainbar}{component}{statusbar}</>;
    }
}



class App extends React.Component {
    constructor() {
        super();
        this.state = { loaded: false, isloading: false, id: 0 };
        window.appframe = this;
    }

    async load() {
        config.server = config.defaultServer;
        const sconfig = await get_json('/config?host=' + encodeURIComponent(window.location.host));
        if (!sconfig.server) return;
        config.server = 'https://' + sconfig.server;
        window.language = sconfig.language;
        window.lan = require('../lan/' + sconfig.language + '/lan.json');
        window.lan.meta = require('../' + sconfig.language + '.json');
        for (const feature in sconfig.feature) {
            let lan;
            try {
                lan = require('../feature/' + feature + '.' + sconfig.feature[feature] + '/' + sconfig.language + '.json');
            } catch (ex) { continue; }
            window.lan[feature] = lan;
            if (lan.logname) window.lan.logname = { ...window.lan.logname, ...lan.logname };
            if (lan.loginfo) window.lan.loginfo = { ...window.lan.loginfo, ...lan.loginfo };
            if (lan.operatortype) window.lan.operatortype = { ...window.lan.operatortype, ...lan.operatortype };
            if (lan.global) {
                lan.global.forEach((item) => {
                    if (!window[item[0]]) window[item[0]] = {};
                    window[item[0]][item[1]] = item[2];
                });
            }
        }
        for (const plugin in sconfig.plugin) {
            let lan;
            try {
                lan = require('../plugin/' + plugin + '/' + sconfig.language + '.json');
            } catch (ex) { continue; }
            window.lan[plugin] = lan;
        }

        this.theme = createTheme(require('../lan/' + sconfig.language + '/theme.json'));

        window.config = { 'site': sconfig };
        window.config.defaultFeature = config.defaultFeature;
        window.config.lansetting = config.lansetting;
        window.config.admin = { id: 0, serialID: 0 };
        if (!window.logfun) window.logfun = {};
        this.setState({ loaded: true });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        if (!this.state.loaded) return (<div className="mframe"><Backdrop sx={{ bgcolor: '#FFFFFF' }} open={true}><CircularProgress color="inherit" /></Backdrop></div>);
        return (
            <ThemeProvider theme={this.theme}><LocalizationProvider dateAdapter={AdapterDayjs}><div className="frame"><Frame id={this.state.id} /></div>
                <Backdrop sx={{ bgcolor: '#FFFFFF' }} open={this.state.isloading}> <CircularProgress color="inherit" /></Backdrop>
            </LocalizationProvider></ThemeProvider>
        );
    }
}



export default App;