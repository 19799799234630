import { initialize_title, modify_title } from '../../main/App';
import { getTimeStr, getIPStr } from '../../main/Utility';
import AbstractTable from '../../main/AbstractTable';



class History extends AbstractTable {

    constructor() {
        super('/feature/history.basic',
            [
                { sortindex: -1, label: window.lan.history.id },
                { sortindex: -1, label: window.lan.history.name },
                { sortindex: -1, label: window.lan.history.inf, style: { width: '60%' } },
                { sortindex: -1, label: window.lan.history.time },
                { sortindex: -1, label: window.lan.history.ip }
            ],
            window.lan.history.infobox);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.history.title);
        return this.pdraw([{ info: window.lan.history.title }], null, this.tdraw());
    }

    drawEmptyCell() {
        return window.lan.history.empty;
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return window.lan.logname[row.type] ? window.lan.logname[row.type] : window.lan.history.unknown;
        if (cellindex === 3) return window.logfun[row.type] && row.information ? window.logfun[row.type](JSON.parse(row.information)) : '';
        if (cellindex === 4) return getTimeStr(row.insertTime);
        if (cellindex === 5) return getIPStr(row.insertIP);
    }
}



export default History;