import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { refresh } from '../../main/App';
import AbstractHandler from '../../main/AbstractHandler';



class Logout extends AbstractHandler {
    constructor(props) {
        super('/feature/logout.basic', 'put');
        this.state = { open: false };
        props.reference.logout = this;
    }

    handle(result, info) {
        this.setState({ open: true });
    }

    render() {
        return (
            <Dialog open={this.state.open} onClose={() => { this.setState({ open: false }); refresh(); }}>
                <DialogTitle>{window.lan.logout.title}</DialogTitle>
                <DialogContent sx={{ width: 500 }}><DialogContentText>{window.lan.logout.okay}</DialogContentText></DialogContent>
                <DialogActions>
                    <Button onClick={() => { this.setState({ open: false }); refresh(); }} autoFocus>{window.lan.general.close}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}



export default Logout;