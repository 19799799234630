export function getTimeStr(timeVal) {
	const time = new Date(timeVal * 1000);
	return window.lan.general.time.replace('%A%', time.getFullYear()).replace('%B%', ('0' + (time.getMonth() + 1)).slice(-2)).replace('%C%', ('0' + time.getDate()).slice(-2))
		.replace('%D%', ('0' + time.getHours()).slice(-2)).replace('%E%', ('0' + time.getMinutes()).slice(-2)).replace('%F%', ('0' + time.getSeconds()).slice(-2));
}

export function getUTCDateStr(timeVal) {
	const time = new Date(timeVal * 1000);
	return window.lan.general.date.replace('%A%', time.getUTCFullYear()).replace('%B%', ('0' + (time.getUTCMonth() + 1)).slice(-2))
		.replace('%C%', ('0' + time.getUTCDate()).slice(-2));
}

export function getIPStr(ip) {
	if (ip < 0) ip += 0x100000000;
	return Math.floor(ip / 16777216) + '.' + Math.floor((ip % 16777216) / 65536) + '.' + Math.floor((ip % 65536) / 256) + '.' + Math.floor(ip % 256);
}

export function checkEmail(email) {
	if (email.length > 127) return false;
	for (let i = 0; i < email.length; i++) {
		if ('0123456789abcdefghijklmnopqrstuvwxyz_-.@'.search(email.charAt(i)) < 0) return false;
	}
	return email.search('@') >= 0;
}

export function checkJSON(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function formulatePrice(price) {
	return (window.config.lansetting.position === 0 ? (window.config.lansetting.currency + ' ') : '') + price + (window.config.lansetting.position === 1 ? (' ' + window.config.lansetting.currency) : '');
}