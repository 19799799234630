import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import AbstractHandler from './AbstractHandler';
import { load, load_component } from './App';



export class AbstractPage extends AbstractHandler {
    constructor(path, infobox = []) {
        super(path, 'get', {});
        this.infobox = infobox;
        this.resultCode = -10;
    }

    result(result, info) {
    }

    handle(result, info) {
        this.resultCode = result;
        if (result === -1) this.forceUpdate();
        if (result >= 0) this.result(result, info);
    }

    componentDidMount() {
        this.submit();
    }

    pdraw(navi, mpanel, rpanel, main) {
        return (
            <div align="center" className="cframe">
                <Grid container><Grid item xs={4} sx={{ padding: '15px' }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                        <Link underline="hover" key="1" color="inherit" href="#" onClick={() => { load('/'); void (0); }}>{window.lan.general.name}</Link>
                        {navi.map((item, i) => {
                            return item.link ? (<Link underline="hover" key="1" color="inherit" href="#" onClick={() => { load(item.link); }}>{item.info}</Link>) : (<Typography key={i} color="text.primary">{item.info}</Typography>);
                        })}
                    </Breadcrumbs>
                </Grid><Grid item xs={4} align="center" sx={{ padding: '15px' }}>
                        {mpanel}
                    </Grid><Grid item xs={4} align="right" sx={{ padding: '15px' }}>
                        {rpanel}
                    </Grid></Grid>
                <Box sx={{ height: 10 }} />
                {main}
            </div>
        );
    }

    draw() {
        return null;
    }

    render() {
        if (this.resultCode < 0 && this.resultCode !== -2) return null;
        if (this.resultCode === -2) return load_component('login');
        return (<>{this.draw()}<Box sx={{ padding: "25px" }}>{this.infobox.map((item, i) => (<Typography key={"infobox_" + i} align="left">{i + 1}. {item}</Typography>))}</Box></>);
    }
}



export default AbstractPage;