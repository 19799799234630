import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SaveIcon from '@mui/icons-material/Save';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

import AbstractPage from './AbstractPage';
import Search from './Search';
import { TextInput, SelectInput, AbstractForm, AbstractDialog } from './AbstractForm';
import DataManager from './DataManager';



class ControlPanel extends React.Component {

    constructor() {
        super();
        this.state = { mode: 0 };
    }

    handleClick() {
        const mode = this.state.mode === 0 ? 1 : 0;
        let index = 0;
        while (document.getElementById('box_' + index)) {
            document.getElementById('box_' + index).style.display = mode === 0 ? 'none' : 'block';
            index++;
        }
        this.setState({ mode: mode });
    }
    render() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.handleClick(); }}>{this.state.mode === 0 ? window.lan.general.frame_show : window.lan.general.frame_hide}</Button>);
    }
}



class SearchList extends Search {
    constructor(props) {
        super(props.reference.reference.searchArr, props.reference);
        this.reference.searchlist = this;
    }

    render() {
        return (
            <List dense>
                {this.reference.value.searchQI.map((item, i) => (
                    <ListItem key={"item_" + i} secondaryAction={<IconButton onClick={this.reference.removeSearch.bind(this.reference, i)} edge="end"><RemoveCircleOutlineIcon fontSize="small" /></IconButton>}>
                        <ListItemAvatar>{i + 1}.</ListItemAvatar>
                        <ListItemText primary={this.text(item)} />
                    </ListItem>
                ))}
            </List>
        );
    }
}



class SearchInput extends Search {
    constructor(props) {
        super(props.reference.reference.searchArr, props.reference);
        this.reference.searchinput = this;
    }

    onchange(name, value) {
        if (name === 'id') {
            this.initialize();
            this.setState({ id: value });
        }
    }
}



class SearchPanel extends AbstractDialog {
    constructor(props) {
        super('', '', { searchQI: [], id: 0 }, props.reference, 'search', window.lan.filter.ss_title, window.lan.filter.search_submit);
    }

    removeSearch(i) {
        this.value.searchQI.splice(i, 1);
        this.searchlist.forceUpdate();
    }

    insertSearch() {
        if (this.value.searchQI.length > 10) {
            this.validity[this.searchinput.getValidity()] = window.lan.filter.overflow;
            this.searchinput.forceUpdate();
            return;
        }
        if (!this.searchinput.validate()) {
            this.searchinput.forceUpdate();
            return;
        }
        this.value.searchQI.push(this.searchinput.read());
        this.searchlist.forceUpdate();
    }

    openmain() {
        this.value.searchQI = this.reference.searchQI.slice();
        super.openmain();
    }

    onchange(name, value) {
        this.searchinput.onchange(name, value);
    }

    options() {
        return [(<SearchList reference={this} />), (
            <List dense>
                <ListItem secondaryAction={<IconButton onClick={this.insertSearch.bind(this)} edge="end"><AddCircleIcon fontSize="small" /></IconButton>}>
                    <ListItemAvatar></ListItemAvatar>
                    <ListItemText primary={(
                        <React.Fragment>
                            <SelectInput form={this} id="id" label={window.lan.filter.searchid} options={
                                this.reference.searchArr.map((option, id) => { return { name: option.name, value: id }; })
                            } /> &nbsp;<SearchInput reference={this} />
                        </React.Fragment>
                    )} />
                </ListItem>
            </List>
        )];
    }

    async submit(path = '') {
        this.reference.searchQI = this.value.searchQI.slice();
        this.close();
        this.reference.reload();
    }
}



class SortList extends React.Component {
    constructor(props) {
        super();
        this.reference = props.reference;
        this.reference.sortlist = this;
    }

    render() {
        return (
            <List dense>
                {this.reference.value.sortQI.map((item, i) => (
                    <ListItem key={"item_" + i} secondaryAction={<IconButton onClick={this.reference.removeSort.bind(this.reference, i)} edge="end"><RemoveCircleOutlineIcon fontSize="small" /></IconButton>}>
                        <ListItemAvatar>{i + 1}.</ListItemAvatar>
                        <ListItemText primary={this.reference.reference.sortArr[parseInt(item[0])] + ' (' + (parseInt(item[1]) === 0 ? window.lan.filter.increasing : window.lan.filter.decreasing) + ')'} />
                    </ListItem>
                ))}
            </List>
        );
    }
}



class SortPanel extends AbstractDialog {
    constructor(props) {
        super('', '', { sortQI: [], sortBy: 0, direction: 0 }, props.reference, 'sort', window.lan.filter.so_title, window.lan.filter.sort_submit);
    }

    removeSort(i) {
        this.value.sortQI.splice(i, 1);
        if (this.value.sortQI.length === 0) this.value.sortQI = this.reference.defaultSortQI.slice();
        this.sortlist.forceUpdate();
    }

    insertSort() {
        for (let i = 0; i < this.value.sortQI.length; i++) {
            if (parseInt(this.value.sortQI[i][0]) === this.value.sortBy) {
                this.validity.sortBy = window.lan.filter.sorterr;
                this.forceUpdate();
                return;
            }
        }
        this.validity.sortBy = '';
        this.value.sortQI.push([this.value.sortBy.toString(), this.value.direction.toString()]);
        this.sortlist.forceUpdate();
    }

    openmain() {
        this.value.sortQI = this.reference.sortQI.slice();
        super.openmain();
    }

    options() {
        return [(<SortList reference={this} />), (
            <List dense>
                <ListItem secondaryAction={<IconButton onClick={this.insertSort.bind(this)} edge="end"><AddCircleIcon fontSize="small" /></IconButton>}>
                    <ListItemAvatar></ListItemAvatar>
                    <ListItemText primary={(
                        <React.Fragment>
                            <SelectInput form={this} id="sortBy" label={window.lan.filter.orderby} options={
                                this.reference.sortArr.map((option, id) => { return { name: option, value: id }; })
                            } /> &nbsp;
                            <SelectInput form={this} id="direction" label={window.lan.filter.direction} options={[
                                { name: window.lan.filter.increasing, value: 0 },
                                { name: window.lan.filter.decreasing, value: 1 }
                            ]} />
                        </React.Fragment>
                    )} />
                </ListItem>
            </List>
        )];
    }

    async submit(path = '') {
        this.reference.sortQI = this.value.sortQI.slice();
        this.close();
        this.reference.reload();
    }
}



class SavePanel extends AbstractDialog {
    constructor(props) {
        super('/feature/filter.basic', 'post', { searchQI: '', sortQI: '', name: '', table: props.table },
            props.reference, 'save', props.search ? window.lan.filter.ss_save : window.lan.filter.so_save, window.lan.filter.save_submit);
    }

    openmain() {
        this.value.searchQI = this.reference.join(this.reference.searchQI);
        this.value.sortQI = this.reference.join(this.reference.sortQI);
        super.openmain();
    }

    options() {
        return [(<TextInput form={this} id="name" label={window.lan.filter.name} fullwidth />)];
    }

    validate(id, value) {
        if (id === 'name') {
            for (let i = 0; i < window.config.admin.filter.length; i++) {
                const element = window.config.admin.filter[i];
                if (element.table === this.value.table && element.name === value) return window.lan.filter.saveerr;
            }
            return value.length === 0 || value.length > 63 ? window.lan.filter.saveerr : '';
        }
        return '';
    }

    handle(result, info) {
        if (result === 0)
            window.config.admin.filter.push({ id: info.id, name: this.value.name, table: this.value.table, searchQI: this.value.searchQI, sortQI: this.value.sortQI });
        super.handle(result, info);
    }

    result(result, info) {
        if (result === 0)
            this.openresult(window.lan.filter.save_okay);
    }
}



class DeletePanel extends AbstractDialog {
    constructor(props) {
        super('/feature/filter.basic', 'delete', { ID: 0 },
            props.reference, 'del', props.search ? window.lan.filter.ss_delete : window.lan.filter.so_delete, window.lan.filter.delete_submit);
        this.table = props.table;
    }

    options() {
        this.reset();
        const options = [{ name: '', value: 0 }];
        if (window.config.admin.filter) window.config.admin.filter.forEach(element => {
            if (element.table === this.table) options.push({ name: element.name, value: element.id });
        });
        return [(<SelectInput form={this} id="ID" label={window.lan.filter.name} options={options} fullwidth />)];
    }

    validate(id, value) {
        if (id === 'ID') return value === 0 ? window.lan.filter.delerr : '';
        return '';
    }

    handle(result, info) {
        if (result === 0)
            window.config.admin.filter = window.config.admin.filter.filter((elem) => (elem.id !== info.id));
        super.handle(result, info);
    }

    result(result, info) {
        if (result === 0)
            this.openresult(window.lan.filter.delete_okay);
    }
}



class FastSearch extends AbstractForm {
    constructor(props) {
        super('', '', { position: 0, string: '' });
        this.reference = props.reference;
    }

    render() {
        const options = [];
        this.reference.searchArr.forEach((element, i) => { if (element.fastsearch) options.push({ name: element.name, value: i }); });
        return (
            <form onSubmit={(e) => { e.preventDefault(); }}>
                <SelectInput form={this} id="position" options={options} />&nbsp;&nbsp;<TextInput form={this} id="string" />
                <IconButton type="submit" sx={{ p: '10px' }} onClick={() => { this.submit(); }}><SearchIcon /></IconButton>
            </form>
        );
    }

    async submit(path = '') {
        this.reference.searchQI = this.value.string !== '' ?
            [this.reference.searchArr[this.value.position].type === 0 ? [this.value.position, this.value.string, 0] : [this.value.position, '%' + this.value.string + '%', 1]] : [];
        this.reference.reload();
    }
}



class FilterPanel extends React.Component {
    constructor(props) {
        super();
        this.state = { mode: 0 };
        this.reference = props.reference;
        this.search = props.search;
        this.table = props.table;
    }

    load(searchQI, sortQI) {
        this.reference.searchQI = this.reference.split(searchQI);
        this.reference.sortQI = this.reference.split(sortQI);
        this.reference.reload();
    }

    render() {
        const options = [];
        if (window.config.admin.filter) window.config.admin.filter.forEach((element, i) => {
            if (element.table === this.table) options.push((<MenuItem dense={true} key={"load_" + i} onClick={() => { this.setState({ mode: 0 }); this.load(element.searchQI, element.sortQI); }}
                disableRipple><ListItemIcon><FilterAltIcon /></ListItemIcon><ListItemText>{element.name}</ListItemText></MenuItem>));
        });
        return (
            <React.Fragment>
                {options.length > 0 ? (
                    <React.Fragment>
                        <Button variant="outlined" disableElevation endIcon={<ArrowDropDownIcon />}
                            onClick={(e) => { this.setState({ mode: 2, main: e.target }); }}>{this.search ? window.lan.filter.ss_load : window.lan.filter.so_load}</Button>
                        <Menu open={this.state.mode === 2} anchorEl={this.state.main} elevation={0} onClose={() => { this.setState({ mode: 0 }); }}>
                            {window.config.admin.filter.map((element, i) => element.table === this.table ? (<MenuItem dense={true} key={"load_" + i} onClick={() => { this.setState({ mode: 0 }); this.load(element.searchQI, element.sortQI); }}
                                disableRipple><ListItemIcon><FilterAltIcon /></ListItemIcon><ListItemText>{element.name}</ListItemText></MenuItem>) : null)}
                        </Menu>
                    </React.Fragment>
                ) : null}&nbsp;
                <Button variant="outlined" disableElevation endIcon={<ArrowDropDownIcon />}
                    onClick={(e) => { this.setState({ mode: 1, main: e.target }); }}>{this.search ? window.lan.filter.sspanel_title : window.lan.filter.sopanel_title}</Button>
                <Menu open={this.state.mode === 1} anchorEl={this.state.main} elevation={0} onClose={() => { this.setState({ mode: 0 }); }}>
                    {this.search ? (
                        <MenuItem dense={true} onClick={() => { this.setState({ mode: 0 }); this.reference.search.openmain(); }}
                            disableRipple><ListItemIcon><ManageSearchIcon /></ListItemIcon><ListItemText>{window.lan.filter.ss_title}</ListItemText></MenuItem>
                    ) : null}
                    <MenuItem dense={true} onClick={() => { this.setState({ mode: 0 }); this.reference.sort.openmain(); }}
                        disableRipple><ListItemIcon><SortByAlphaIcon /></ListItemIcon><ListItemText>{window.lan.filter.so_title}</ListItemText></MenuItem>
                    {window.config.admin.id > 1 ? <Divider /> : null}
                    {window.config.admin.id > 1 ? (
                        <MenuItem dense={true} onClick={() => { this.setState({ mode: 0 }); this.reference.save.openmain(); }}
                            disableRipple><ListItemIcon><SaveIcon /></ListItemIcon><ListItemText>{this.search ? window.lan.filter.ss_save : window.lan.filter.so_save}</ListItemText></MenuItem>
                    ) : null}
                    {window.config.admin.id > 1 && options.length > 0 ? (
                        <MenuItem dense={true} onClick={() => { this.setState({ mode: 0 }); this.reference.del.openmain(); }}
                            disableRipple><ListItemIcon><DeleteIcon /></ListItemIcon><ListItemText>{this.search ? window.lan.filter.ss_delete : window.lan.filter.so_delete}</ListItemText></MenuItem>
                    ) : null}
                </Menu>
                {this.search ? (<SearchPanel reference={this.reference} />) : null}
                <SortPanel reference={this.reference} />
                <SavePanel reference={this.reference} search={this.search} table={this.table} />
                <DeletePanel reference={this.reference} table={this.table} />
            </React.Fragment>
        );
    }
}



class AbstractTable extends AbstractPage {
    constructor(path, description, infobox = [], table = '', searchQI = '', sortQI = '', searchArr = [], sortArr = [], manageable = false, details = false) {
        super(path + (path.indexOf('?') < 0 ? '?' : '&') + 'searchQI=' + searchQI + '&sortQI=' + sortQI, infobox);
        this.basicpath = path;
        this.description = description;
        this.manageable = manageable;
        this.details = details;
        this.searchQI = this.split(searchQI);
        this.sortQI = this.split(sortQI);
        this.defaultSortQI = this.split(sortQI);
        this.table = table;
        this.searchArr = searchArr;
        this.sortArr = sortArr;

        this.data = [];
        this.dm = new DataManager();
        this.state = { selected: [] };
    }

    result(result, info) {
        if (info.buffer) this.dm.buffer(info.buffer);
        this.data = info.result;
        this.setState({ selected: [] });
        this.forceUpdate();
    }

    split(str) {
        if (str === '') return [];
        const arr = str.split('|');
        return arr.map((part) => this.splitPart(part));
    }

    splitPart(str) {
        if (str === '') return [];
        const arr = str.split('$');
        return arr.map((part) => decodeURIComponent(part));
    }

    join(arr) {
        if (arr.length === 0) return '';
        return arr.map((part) => this.joinPart(part)).join('|');
    }

    joinPart(arr) {
        if (arr.length === 0) return '';
        return arr.map((part) => encodeURIComponent(part)).join('$');
    }

    handleRequestSort(position, event) {
        this.sortQI = [[position.toString(), parseInt(this.sortQI[0][0]) === position && parseInt(this.sortQI[0][1]) === 0 ? "1" : "0"]];
        this.submit({}, this.basicpath + (this.basicpath.indexOf('?') < 0 ? '?' : '&') + 'searchQI=' + this.join(this.searchQI) + '&sortQI=' + this.join(this.sortQI) + '&' + this.dm.parameter(this.dm.page, true));
    };

    handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = this.data.map((n) => n.ID);
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleSelect(event, id) {
        const position = this.state.selected.indexOf(id);
        this.setState({ selected: position < 0 ? this.state.selected.concat([id]) : this.state.selected.slice(0, position).concat(this.state.selected.slice(position + 1)) });
    };

    handleChangePage(event, newPage) {
        this.dm.setPage(newPage);
        this.submit({}, this.basicpath + (this.basicpath.indexOf('?') < 0 ? '?' : '&') + 'searchQI=' + this.join(this.searchQI) + '&sortQI=' + this.join(this.sortQI) + '&' + this.dm.parameter(newPage));
    };

    reload() {
        this.submit({}, this.basicpath + (this.basicpath.indexOf('?') < 0 ? '?' : '&') + 'searchQI=' + this.join(this.searchQI) + '&sortQI=' + this.join(this.sortQI) + '&' + this.dm.parameter(0, true));
    }

    refresh() {
        this.submit({}, this.basicpath + (this.basicpath.indexOf('?') < 0 ? '?' : '&') + 'searchQI=' + this.join(this.searchQI) + '&sortQI=' + this.join(this.sortQI) + '&' + this.dm.parameter(this.dm.page));
    }

    drawMenu(row, rowindex) {
        return null;
    }

    drawDetail(row, rowindex) {
        return null;
    }

    drawCell(row, rowindex, cellindex) {
        return null;
    }

    drawEmptyCell() {
        return null;
    }

    drawToolbarLeft() {
        return null;
    }

    drawToolbarRight() {
        return null;
    }

    pdraw(navi, panel, main) {
        return super.pdraw(navi, this.search ? (<FastSearch reference={this} search={this.searchArr.length > 0} />) : null, (<React.Fragment>{panel}&nbsp;{this.table !== '' ? (<FilterPanel reference={this} search={this.searchArr.length > 0} table={this.table} />) : null}&nbsp;{this.details ? (<ControlPanel />) : null}</React.Fragment>), main);
    }

    tdraw() {
        const toolbarLeft = this.drawToolbarLeft();
        const toolbarRight = this.drawToolbarRight();
        return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', boxShadow: 0 }}>
                    <TableContainer>
                        <Table size='small' >
                            <TableHead>
                                <TableRow>
                                    {this.manageable ? (
                                        <TableCell padding="checkbox">
                                            <Checkbox color="primary"
                                                indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.data.length}
                                                checked={this.data.length > 0 && this.state.selected.length === this.data.length}
                                                onChange={this.handleSelectAllClick.bind(this)}
                                            />
                                        </TableCell>
                                    ) : null}
                                    {this.manageable ? (<TableCell></TableCell>) : null}
                                    {this.details ? (<TableCell></TableCell>) : null}
                                    {this.description.map((headCell, i) => (
                                        <TableCell key={'tableheader_' + i} align='left' padding='normal' style={headCell.style}>
                                            {headCell.sortindex >= 0 ? (
                                                <TableSortLabel
                                                    active={parseInt(this.sortQI[0][0]) === headCell.sortindex}
                                                    direction={parseInt(this.sortQI[0][1]) === 0 ? 'asc' : 'desc'}
                                                    onClick={this.handleRequestSort.bind(this, headCell.sortindex)}
                                                >
                                                    <b>{headCell.label}</b>
                                                </TableSortLabel>
                                            ) : (<div><b>{headCell.label}</b></div>)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.data.map((row, index) => {
                                    const isSelected = this.state.selected.indexOf(row.ID) >= 0;
                                    const menu = this.drawMenu(row, index);
                                    const detail = this.drawDetail(row, index);
                                    return (<React.Fragment key={'fragement_' + index}>
                                        <TableRow hover role="checkbox" tabIndex={index} key={row.ID} selected={isSelected}>
                                            {this.manageable ? (
                                                <TableCell sx={{ border: '0px' }} padding="checkbox" onClick={(event) => { this.handleSelect(event, row.ID); }}>
                                                    <Checkbox color="primary" checked={isSelected} />
                                                </TableCell>
                                            ) : null}
                                            {this.manageable ? (
                                                <TableCell sx={{ border: '0px' }} padding="checkbox">{menu}</TableCell>
                                            ) : null}
                                            {this.details ? (
                                                <TableCell sx={{ border: '0px' }} padding="checkbox">
                                                    {detail ? (<Button color="secondary" disableElevation onClick={() => { document.getElementById("box_" + index).style.display = document.getElementById("box_" + index).style.display === 'block' ? 'none' : 'block'; }}>
                                                        {window.lan.general.detail}
                                                    </Button>) : null}
                                                </TableCell>
                                            ) : null}
                                            {this.description.map((headCell, i) =>
                                                (<TableCell sx={{ padding: '10px', border: '0px' }} component="td" key={'tablecell_' + i} scope="row">{this.drawCell(row, index, i + 1)}</TableCell>))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ padding: '0px', bgcolor: '#F4F4F4' }} component="td" key={'tablecellinf_-1'} colSpan={(this.manageable ? 2 : 0) + (this.details ? 1 : 0)}></TableCell>
                                            <TableCell sx={{ padding: '0px', bgcolor: '#F4F4F4' }} component="td" key={'tablecellinf_-2'} align="center" scope="row" colSpan={this.description.length}><Box id={"box_" + index} sx={{ display: 'none' }}>
                                                {detail}
                                            </Box></TableCell>
                                        </TableRow>
                                    </React.Fragment>);
                                })}
                                {this.data.length === 0 ? (
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell sx={{ padding: '10px' }} component="td" key={'tablecell_-3'} align="center" scope="row" colSpan={this.description.length + (this.manageable ? 2 : 0) + (this.details ? 1 : 0)}>{this.drawEmptyCell()}</TableCell>
                                    </TableRow>
                                ) : null}
                                {toolbarLeft !== null || toolbarRight !== null ? (
                                    <TableRow hover role="checkbox" tabIndex={-1}>
                                        <TableCell className="fun-panel" sx={{ padding: '8px', paddingLeft: '18px', paddingRight: '18px' }} component="td" key={'tablecell_-4'} scope="row" colSpan={this.description.length + (this.manageable ? 2 : 0) + (this.details ? 1 : 0)}>
                                            <Grid container><Grid item xs={6}>{toolbarLeft}</Grid><Grid item xs={6} align="right">{toolbarRight}</Grid></Grid></TableCell>
                                    </TableRow>) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination rowsPerPageOptions={[this.dm.size]} component="div" count={this.dm.records} rowsPerPage={this.dm.size} page={this.dm.page}
                        onPageChange={this.handleChangePage.bind(this)} getItemAriaLabel={(type) => { return window.lan.general.goto_page.replace('%A%', window.lan.general['page_' + type]); }}
                        labelDisplayedRows={(para) => { return window.lan.general.page.replace('%A%', para.from).replace('%B%', para.to).replace('%C%', para.count); }} />
                </Paper>
            </Box>
        );
    }
}



export default AbstractTable;