import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

import { initialize_title, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, TextInput, SelectInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/group.basic', 'post', { name: '', index: '' }, props.reference, 'add_diag', window.lan.group.add, window.lan.group.add_submit);
    }

    options() {
        const options = [];
        const arr = window.config.buffer ? window.config.buffer['group/group'].map((item) => item.index) : [];
        for (let i = 8; i < 63; i++) if (!arr.includes(i)) options.push({ name: i, value: i });
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.group.add_name} fullwidth />
                <SelectInput form={this} id="index" label={window.lan.group.add_index} options={options} fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.group.err[0] : '';
        if (id === 'index') return isNaN(parseInt(value)) || parseInt(value) < 1 || parseInt(value) > 62 ? window.lan.group.err[3] : '';
        return '';
    }

    result(result, info) {
        if (result === 1 || result === 9) {
            this.validity['index'] = window.lan.group.err[3];
            this.openmain();
            return;
        }
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/group.basic', 'put', {}, props.reference, 'edit_diag', window.lan.group.edit, window.lan.group.edit_submit);
    }

    getRightSelection(key, info) {
        const options = info.level.map((name, i) => ({ name: name, value: i }));
        return (<TooltipWrapper key={key} input={<SelectInput form={this} id={'_right_' + key} label={info.name} options={options} fullwidth />} tooltip={info.tip} />);
    }

    openmain() {
        const rightObj = JSON.parse(this.value.right);
        Object.keys(window.lan.right).forEach((key) => {
            this.value['_right_' + key] = rightObj[key] ? rightObj[key] : 0;
        });

        super.openmain();
    }

    options() {
        const options = this.value['index'] > 7 ? [
            (<TextInput form={this} id="name" label={window.lan.group.edit_name} fullwidth />)
        ] : [];
        options.push(
            (<TextInput form={this} id="remark" label={window.lan.group.edit_remark} fullwidth multiline rows={4} />)
        );
        let buffer = [];
        Object.entries(window.lan.right).forEach(([key, info]) => {
            if (buffer.length === 3) {
                options.push(<Stack direction="row" spacing={5}>{buffer}</Stack>);
                buffer = [];
            }
            buffer.push(this.getRightSelection(key, info));
        });
        if (buffer.length > 0) {
            while (buffer.length < 3) buffer.push(<Box sx={{ width: '100%' }}></Box>);
            options.push(<Stack direction="row" spacing={5}>{buffer}</Stack>);
        }
        return options;
    }

    presubmit() {
        if (!super.presubmit()) return false;

        const right = {};
        Object.keys(window.lan.right).forEach((key) => {
            if (this.value['_right_' + key] > 0) right[key] = this.value['_right_' + key];
        });
        this.value.right = JSON.stringify(right);
        for (const key in this.value) if (key.charAt(0) === '_') delete this.value[key];
        return true;
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.group.err[0] : '';
        if (id === 'remark') return value.length > 4095 ? window.lan.group.err[2] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/group.basic', 'delete', {}, props.reference, 'del_diag', window.lan.group.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.group.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.group.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        if (props.row.index >= 8) items.push({ name: window.lan.group.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Group extends AbstractTable {

    constructor() {
        super('/feature/group.basic',
            [
                { sortindex: 0, label: window.lan.group.id },
                { sortindex: 1, label: window.lan.group.index },
                { sortindex: 2, label: window.lan.group.name },
                { sortindex: 3, label: window.lan.group.insert_time },
                { sortindex: 4, label: window.lan.group.update_time }
            ],
            window.lan.group.infobox, 'Group', '', '0$0', [],
            [
                window.lan.group.id,
                window.lan.group.index,
                window.lan.group.name,
                window.lan.group.inert_time,
                window.lan.group.update_time
            ], true, true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.group.title);
        return this.pdraw([{ info: window.lan.group.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.group.remark}:</b><br />{row.remark !== '' ? row.remark : window.lan.general.none}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.index;
        if (cellindex === 3) return (<Stack direction="row" spacing={2}><Typography>{row.index < 8 ? (window.groupname[row.index] ? window.groupname[row.index] : '') : row.name}</Typography>
            {row.index < 8 ? <Chip label={window.lan.group.system} color="primary" size="small" /> : null}</Stack>);
        if (cellindex === 4) return getTimeStr(row.insertTime);
        if (cellindex === 5) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.group.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (!this.checkIDList()) { this.del_diag.openresult(window.lan.group.err[1]); return; } if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.group.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.group.add}</Button>);
    }

    checkIDList() {
        const arr = window.config.buffer['group/group'].map((item) => this.state.selected.includes(item.id) ? item.index : -1);
        for (let i = 0; i < 8; i++) if (arr.includes(i)) return false;
        let i = 0;
        let group = window.config.admin.group;
        while (group > 0) {
            if (group % 2 > 0 && arr.includes(i)) return false;
            i++;
            group /= 2;
        }
        return true;
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_group = function (info) {
    return window.lan.loginfo.add_group.replace('%A%', info.id).replace('%B%', info.index).replace('%C%', info.name);
}

window.logfun.remove_groups = function (info) {
    return window.lan.loginfo.remove_groups.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_group = function (info) {
    return window.lan.loginfo.edit_group.replace('%A%', info.id).replace('%B%', info.name).replace('%C%', info.right);
}



export default Group;